import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import akdaş from "./Port/akdaş.svg";
import deal from "./Port/deal.svg";
import pilates from "./Port/pilates.svg";
import romac from "./Port/romac.svg";

const PortfolioSection = () => {
    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start center", "center center"], // Trigger halfway through scrolling
        once: true,
    });

    const portfolioItems = [
        {
            id: 1,
            image: akdaş,
            title: "Akdaş",
            description:
                "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 2,
            image: deal,
            title: "Dealn",
            description:
                "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 3,
            image: romac,
            title: "Romac",
            description:
                "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 4,
            image: pilates,
            title: "Take Care Pilates",
            description:
                "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
    ];

    const scales = useTransform(scrollYProgress, [0, 0.3, 0.5, 0.7, 1], [0.3, 0.6, 1, 1, 1]);
    const rotates = [
        useTransform(scrollYProgress, [0, 0.5, 1], [-15, 0, 0]),
        useTransform(scrollYProgress, [0, 0.5, 1], [5, 0, 0]),
        useTransform(scrollYProgress, [0, 0.5, 1], [25, 0, 0]),
        useTransform(scrollYProgress, [0, 0.5, 1], [-5, 0, 0]),
    ];
    const positions = [
        { x: useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, -300]), y: useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, -300]) },
        { x: useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, 300]), y: useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, -300]) },
        { x: useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, -300]), y: useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, 300]) },
        { x: useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, 300]), y: useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, 300]) },
    ];

    return (
        <div
            ref={containerRef}
            className="h-full relative bg-white w-5/6 mx-auto"
        >
            {/* Desktop Layout */}
            <div className="sticky top-0 h-full flex flex-col items-center justify-center overflow-hidden max-md:hidden">
                <p className="text-black text-[64px] font-bold text-left mr-auto mt-[10vh]">Portfolio</p>
                <motion.div
                    className="relative w-full h-[130vh] flex items-center justify-center"
                    style={{
                        perspective: 2000,
                    }}
                >
                    {portfolioItems.map((item, index) => (
                        <motion.div
                            key={item.id}
                            className="absolute w-[500px] rounded-xl overflow-hidden shadow-2xl cursor-pointer"
                            style={{
                                x: positions[index].x,
                                y: positions[index].y,
                                scale: scales, // Dynamically bound to scrollYProgress
                                rotate: rotates[index],
                                transformStyle: "preserve-3d",
                                transformOrigin: "center center",
                            }}
                        >
                            <img
                                src={item.image}
                                alt={item.title}
                                className="w-full h-full object-cover"
                            />
                            <div className="p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                    {item.title}
                                </h2>
                                <p className="text-gray-600 text-sm leading-relaxed">
                                    {item.description}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
            <p className=" md:hidden text-black text-[40px] font-semibold text-left mr-auto mt-[5vh]">Portfolio</p>
            {/* Mobile Layout */}
            <div className="md:hidden w-full overflow-x-auto flex gap-4 px-4  flex-row justify-start items-center min-h-fit overflow-y-hidden">

                {portfolioItems.map((item, index) => (
                    <motion.div
                        key={item.id}
                        className="flex-shrink-0 w-[350px] rounded-xl overflow-hidden cursor-pointer"
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            className="w-full h-[260px] object-cover"
                        />
                        <div className="p-2">
                            <h2 className="text-lg font-semibold text-gray-800 mb-2 text-left">
                                {item.title}
                            </h2>
                            <p className="text-gray-600 text-sm leading-relaxed text-left">
                                {item.description}
                            </p>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default PortfolioSection;
