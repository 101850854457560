import React, { useState, useEffect, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import marka from "./Assets/marka.svg";
import web from "./Assets/web.svg";
import digital from "./Assets/digital.svg";
import sosyal from "./Assets/sosyal.svg";
import pro from "./Assets/pro.svg";

const Services = () => {
    const containerRef = useRef(null);

    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start start", "end end"]
    });

    const services = [
        {
            title: "Marka Danışmanlığı",
            description:
                "Markanızın kişiliğini oluşturuyor, hikayenizi dijital dünyaya etkili bir şekilde yansıtıyoruz. Markanızın kişiliğini oluşturuyor, hikayenizi dijital dünyaya etkili bir şekilde yansıtıyoruz.",
            image: marka,
            bgColor: "#E70000" // 1) Add a unique background color here
        },
        {
            title: "Web Sitesi Tasarımı",
            description:
                "Markanıza özel özgün ve kullanıcı dostu web siteleri tasarlıyoruz. Mobil uyumluluk ve hız bizim işimiz.",
            image: web,
            bgColor: "#1F1F1F"
        },
        {
            title: "Dijital Pazarlama",
            description:
                "Markanızı dijital dünyada öne çıkaracak stratejiler geliştiriyor, Google ve sosyal medya reklamları ile hedef kitlenizi büyütüyoruz.",
            image: digital,
            bgColor: "#555555"
        },
        {
            title: "Sosyal Medya Yönetimi",
            description:
                "Markanızı sosyal medyada yıldız yapıyor, etkileşimlerinizi zirveye çıkarıyoruz.",
            image: sosyal,
            bgColor: "#E70000"
        },
        {
            title: "Prodüksiyon Hizmetleri",
            description:
                "Tanıtım videoları, reklam filmleri ve belgesellerle markanızı görsel olarak taçlandırıyoruz.",
            image: pro,
            bgColor: "#1F1F1F"
        }
    ];

    // Create individual progress values for each service
    const progress1 = useTransform(scrollYProgress, [0, 0.2], [226.2, 0]);
    const progress2 = useTransform(scrollYProgress, [0.2, 0.4], [226.2, 0]);
    const progress3 = useTransform(scrollYProgress, [0.4, 0.6], [226.2, 0]);
    const progress4 = useTransform(scrollYProgress, [0.6, 0.8], [226.2, 0]);
    const progress5 = useTransform(scrollYProgress, [0.8, 1], [226.2, 0]);

    const progressArray = [progress1, progress2, progress3, progress4, progress5];

    // Create opacity values for each service
    const opacity1 = useTransform(scrollYProgress, [0, 0.2, 0.4], [1, 1, 0]);
    const opacity2 = useTransform(scrollYProgress, [0.2, 0.4, 0.6], [1, 1, 0]);
    const opacity3 = useTransform(scrollYProgress, [0.4, 0.6, 0.8], [1, 1, 0]);
    const opacity4 = useTransform(scrollYProgress, [0.6, 0.8, 1], [1, 1, 0]);
    const opacity5 = useTransform(scrollYProgress, [0.8, 1], [1, 1]);

    const opacityArray = [opacity1, opacity2, opacity3, opacity4, opacity5];

    return (
        <div ref={containerRef} className="relative w-full bg-[#1f1f1f]">
            <motion.p
                className="text-white text-2xl md:text-4xl mb-4 text-center pt-20"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                Hizmetlerimiz
            </motion.p>
            {services.map((service, index) => (
                <motion.div
                    key={index}
                    className="sticky top-0 h-screen flex flex-col justify-end items-center"
                >
                    <div
                        className={`relative w-full  mx-auto rounded-[10px] overflow-hidden flex items-end`}
                        style={{
                            backgroundColor: service.bgColor,
                            height: `${90 - index * 2}vh`, // Dynamically adjust the height
                            position: "relative",
                        }}
                    >
                        <div className="w-full">
                            {/* Progress Circle */}
                            <div className="absolute top-8 right-8 w-20 h-20">
                                <svg className="w-full h-full transform -rotate-90">
                                    <circle
                                        cx="40"
                                        cy="40"
                                        r="36"
                                        fill="none"
                                        stroke="#1f1f1f"
                                        strokeWidth="2"
                                        className="opacity-25"
                                    />
                                    <motion.circle
                                        cx="40"
                                        cy="40"
                                        r="36"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="4"
                                        strokeDasharray="226.2"
                                        style={{
                                            strokeDashoffset: progressArray[index],
                                        }}
                                    />
                                </svg>
                                <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-medium">
                                    {index + 1}/{services.length}
                                </span>
                            </div>

                            {/* Content */}
                            <div className="flex flex-col h-full p-12 gap-8 w-4/5 mx-auto max-md:w-11/12 max-md:mb-[20vh] md:mb-[10vh]">
                                <div className="flex flex-col items-center justify-center">
                                    <motion.h2
                                        className="text-white text-4xl lg:text-6xl font-bold mb-8 max-md:text-[30px]"
                                        initial={{ opacity: 0, y: 20 }}
                                        whileInView={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.6, delay: 0.2 }}
                                    >
                                        {service.title}
                                    </motion.h2>
                                </div>
                                <img
                                    src={service.image}
                                    alt={service.title}
                                    className="w-full h-full object-cover md:hidden"
                                />
                                <motion.div
                                    className="flex items-center"
                                    initial={{ opacity: 0, x: 20 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.6, delay: 0.4 }}
                                >
                                    <p className="text-white text-[30px] font-light leading-relaxed max-md:text-[13px]">
                                        {service.description}
                                    </p>
                                </motion.div>
                                <img
                                    src={service.image}
                                    alt={service.title}
                                    className="w-full h-[300px] object-cover max-md:hidden"
                                />
                            </div>
                        </div>
                    </div>
                </motion.div>

            ))}
        </div>
    );
};

export default Services;
