import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import logo from "./Assets/logo.svg";
import menuIcon from "./Assets/hamburgerIcon.svg";
import closeIcon from "./Assets/closeIcon.svg";
import NavbarLogo from "./Assets/NavbarLogo.svg";
import navbarLine from "./Assets/navbarLine.svg";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <div className="relative">
            <NavbarDefault toggleMenu={toggleMenu} />
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        initial={{ y: "-100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "-100%" }}
                        transition={{ type: "spring", damping: 20, stiffness: 100 }}
                        className="fixed top-0 left-0 w-full h-full z-30 bg-white"
                    >
                        <NavbarOpened toggleMenu={toggleMenu} />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const NavbarDefault = ({ toggleMenu }) => (
    <div className="fixed top-0 left-0 w-full p-4 z-20 flex justify-between items-center bg-transparent max-md:p-2">
        {/* Logo */}
        <Link to="/">
            <img
                src={logo}
                alt="Logo"
                className="w-[160px] h-[70px] object-contain max-md:w-[80px] max-md:h-[35px]"
            />
        </Link>

        {/* Catalog button */}
        <Link
            to="/catalog"
            className="w-[147px] h-[40px] rounded-lg bg-[#E70000] text-white text-[16px] text-center flex items-center justify-center max-md:w-[100px] max-md:h-[30px] max-md:text-[12px]"
        >
            Katalog
        </Link>

        {/* Menu button */}
        <button onClick={toggleMenu} className="focus:outline-none">
            <img
                src={menuIcon}
                alt="Menu Icon"
                className="w-[60px] h-[60px] object-contain max-md:w-[40px] max-md:h-[40px]"
            />
        </button>
    </div>
);

const NavbarOpened = ({ toggleMenu }) => (
    <div className="fixed top-0 left-0 w-full h-full z-30 bg-white max-md:pt-4 max-md:min-h-screen ">
        {/* Overlay background */}
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 z-10"></div>

        {/* Dropdown Menu */}
        <div className="absolute top-[8vh] left-0 w-full z-30 max-md:top-0 ">
            <div className="bg-white mx-auto w-full px-3 p-8 max-md:p-4 max-md:pt-16 max-md:h-screen">
                {/* Navbar header */}
                <div className="fixed top-0 left-0 w-full p-4 z-30 flex justify-between items-center bg-white max-md:p-3">
                    <Link to="/">
                        <img
                            src={NavbarLogo}
                            alt="Logo"
                            className="w-[160px] h-fit object-fill max-md:w-[100px]"
                        />
                    </Link>

                    <Link
                        to="/catalog"
                        className="w-[147px] h-[40px] rounded-lg bg-[#E70000] text-white text-[16px] text-center flex items-center justify-center max-md:w-[100px] max-md:h-[30px] max-md:text-[12px]"
                    >
                        Katalog
                    </Link>

                    <button onClick={toggleMenu} className="focus:outline-none">
                        <img
                            src={closeIcon}
                            alt="Close Icon"
                            className="w-[40px] h-[40px] object-contain max-md:w-[30px] max-md:h-[30px]"
                        />
                    </button>
                </div>

                {/* Navigation links */}
                <nav className="flex flex-col items-center text-center mt-16 max-md:mt-12">
                    <div className="w-full flex flex-col items-center gap-4 max-md:gap-2">
                        <Link
                            to="/about"
                            className="text-[#25201F] text-[30px] hover:underline font-semibold max-md:text-[20px]"
                        >
                            Hakkımızda
                        </Link>
                        <Link
                            to="/services"
                            className="text-[#25201F] text-[30px] hover:underline font-semibold max-md:text-[20px]"
                        >
                            Hizmetlerimiz
                        </Link>
                        <Link
                            to="/work"
                            className="text-[#25201F] text-[30px] hover:underline font-semibold max-md:text-[20px]"
                        >
                            İşlerimiz
                        </Link>
                        <Link
                            to="/contact"
                            className="text-[#25201F] text-[30px] hover:underline font-semibold max-md:text-[20px]"
                        >
                            İletişim
                        </Link>
                    </div>

                    {/* Social links */}
                    <div className="flex justify-around mt-8 w-full max-md:mt-4">
                        <Link
                            to="/facebook"
                            className="text-[#E70000] underline text-[16px] max-md:text-[12px]"
                        >
                            Facebook
                        </Link>
                        <Link
                            to="/instagram"
                            className="text-[#E70000] underline text-[16px] max-md:text-[12px]"
                        >
                            Instagram
                        </Link>
                        <Link
                            to="/linkedin"
                            className="text-[#E70000] underline text-[16px] max-md:text-[12px]"
                        >
                            Linkedin
                        </Link>
                    </div>
                </nav>
            </div>
        </div>
    </div>
);

export default Navbar;
