import React from "react";
import blog1 from "./Assets/blog1.svg";
import blog2 from "./Assets/blog2.svg";
import blog3 from "./Assets/blog3.svg";
import footerLogo from "./Assets/footerLogo.svg";
import line from "./Assets/line.svg";
import { motion } from "framer-motion";
const Footer = () => {
    const blogPosts = [
        {
            id: 1,
            image: blog1,
            title: "Yapay Zeka ve Makine Öğrenimi ile Dijital Pazarlama :Geleceğin Stratejileri Giriş",
            description: "Dijital pazarlama dünyasında yenilikler ve gelişmeler hız kesmeden devam ediyor.",
        },
        {
            id: 2,
            image: blog2,
            title: "Sosyal Medyada Video İçeriklerinin Gücü: Markanızı Nasıl Yükseltebilirsiniz?",
            description: "Sosyal medya platformlarında video içerikleri, kullanıcılar arasında hızla popülerlik...",
        },
        {
            id: 3,
            image: blog3,
            title: "2024'te SEO Trendleri: Başarılı Bir Strateji İçin Bilmeniz Gerekenler",
            description: "SEO (Arama Motoru Optimizasyonu), dijital pazarlamanın en önemli bileşenlerinden....",
        },
    ];
    const cardVariants = (index) => ({
        hidden: {
            opacity: 0,
            x: index % 2 === 0 ? -100 : 100, // Slide in from left if even, right if odd
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut",
            },
        },
    });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2, // Delay between cards
            },
        },
    };
    return (
        <section className="bg-custom-gradient text-white py-10 overflow-x-hidden">
            {/* Blog Header */}
            <div className="w-11/12 mx-auto px-4 mb-10">
                <h1 className="text-[64px] font-semibold text-[#1F1F1F] text-left max-md:text-[40px]">Blog</h1>
            </div>
            {/* Blog Section */}
            <motion.div
                className="w-11/12 mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-6 pb-[10vh] max-md:flex max-md:overflow-x-scroll max-md:gap-4 max-md:pb-4"
                variants={containerVariants}
                initial="hidden"
                whileInView="visible" // Triggers the animation when in viewport
                viewport={{ once: true, amount: 0.2 }} // Animate once, 20% visibility
            >
                {blogPosts.map((post, index) => (
                    <motion.div
                        key={post.id}
                        className="rounded-[10px] overflow-hidden cursor-pointer lg:min-w-[300px] max-md:min-w-full"
                        variants={cardVariants(index)}
                    >
                        <img
                            src={post.image}
                            alt={post.title}
                            className="w-full h-[400px] object-cover"
                        />
                        <div className="text-left">
                            <h2 className="text-[32px] font-semibold mb-2 max-md:text-[25px]">{post.title}</h2>
                            <p className="text-[24px] text-white font-light max-md:text-[14px]">{post.description}</p>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
            {/* Footer */}
            <img src={line} alt="Line" className="w-11/12 mx-auto h-[1px] object-cover" />
            <footer className="text-white mt-10 py-8 w-full flex justify-around text-left items-start max-md:flex-col max-md:gap-6 max-md:px-4">
                {/* Logo and Email */}
                <div>
                    <img src={footerLogo} alt="Logo" className="w-[160px] h-[40px] object-fit" />
                </div>
                <div>
                    <p className="font-semibold max-md:text-[16px]">Social Media</p>
                    <ul className="space-y-2 mt-2">
                        <li>Instagram</li>
                        <li>Facebook</li>
                        <li>LinkedIn</li>
                    </ul>
                </div>
                {/* Contact Info */}
                <div>
                    <p className="font-semibold max-md:text-[14px]">
                        Gaziosmanpaşa, Büyükesat Mah, <br />Kaptanpaşa Sok. No:23/A, 06450 <br /> Çankaya/Ankara
                    </p>
                    <p className="mt-2 max-md:text-[14px]">+90 123 456 789</p>
                </div>
            </footer>
        </section>
    );
}
export default Footer;