import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import strategy from "./Assets/strategy.svg";

const WhyHorus = () => {
    const sections = [
        {
            image: strategy,
            title: "Özel Stratejiler",
            subtitle: "Markanızın hikayesini öne çıkaran, özgün planlar.",
        },
        {
            image: strategy,
            title: "Yaratıcı İçerik",
            subtitle: "Etkileyici ve dikkat çeken içeriklerle dijital dünyada fark yaratın.",
        },
        {
            image: strategy,
            title: "Dinamik Ekip",
            subtitle: "Yenilikleri sürekli takip eden bir ekiple tanışmaya hazır olun.",
        },
    ];

    // Animation control
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.3, // Trigger when 30% of the container is visible
    });

    // Start animation when inView is true
    React.useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    // Animation variants
    const cardVariants = {
        hidden: { opacity: 0, scale: 0.8, y: 50 }, // Initial state
        visible: (i) => ({
            opacity: 1,
            scale: 1,
            y: 0,
            transition: {
                delay: i * 0.2, // Stagger delay for each card
                duration: 0.8,
                type: "spring",
                stiffness: 100,
                damping: 10,
            },
        }),
        hover: {
            scale: 1.1,
            boxShadow: "0px 8px 20px rgba(231, 0, 0, 0.5)",
            transition: {
                duration: 0.3,
                ease: "easeOut",
            },
        },
    };

    return (
        <section className="bg-[#1f1f1f] min-h-screen text-white py-10 px-4 flex flex-col items-center justify-center">
            {/* Header */}
            <h2 className="text-3xl md:text-4xl font-bold mb-12">
                Neden <span className="text-red-600">Horus Medya?</span>
            </h2>

            {/* Cards Container */}
            <div
                ref={ref} // Attach the Intersection Observer ref here
                className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:max-w-6xl w-full max-md:overflow-x-auto max-md:gap-4 max-md:w-full max-md:flex max-md:flex-row"
            >
                {sections.map((section, index) => (
                    <motion.div
                        key={index}
                        className="border border-[#E70000] rounded-[11px] box-border p-6 flex flex-col items-start justify-center h-fit min-h-[460px] max-md:w-[350px] max-md:h-[380px]"
                        style={{
                            background: "linear-gradient(180deg, #1F1F1F 0%, #E70000 100%)",
                        }}
                        custom={index} // Pass index for staggered animations
                        initial="hidden"
                        animate={controls} // Controlled by Intersection Observer
                        whileHover="hover"
                        variants={cardVariants}
                    >
                        <h3 className="text-[50px] font-semibold mb-2 text-center">
                            {section.title}
                        </h3>
                        <p className="font-light text-[20px] leading-relaxed text-center">
                            {section.subtitle}
                        </p>
                    </motion.div>
                ))}
            </div>

            <p
                style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "20px",
                    lineHeight: "35px",
                    textAlign: "center",
                    letterSpacing: "-0.03em",
                    color: "#FFFFFF",
                }}
                className="w-3/5 mx-auto mt-[10vh] max-md:w-full"
            >
                Çünkü <span style={{ fontWeight: 700 }}>detaycıyız</span>, yaratıcıyız ve her zaman
                işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz eder,
                size en uygun stratejiyi geliştiririz. Bizimle çalışmak demek, markanızın{" "}
                <span style={{ fontWeight: 700 }}>doğru ellerde</span> olduğunu bilmek demek.
            </p>
        </section>
    );
};

export default WhyHorus;
